import { render, staticRenderFns } from "./MainArea.vue?vue&type=template&id=6520dd09&scoped=true"
import script from "./MainArea.vue?vue&type=script&lang=js"
export * from "./MainArea.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6520dd09",
  null
  
)

export default component.exports